import {
  BooleanParam,
  DateTimeParam,
  StringParam,
  withDefault,
} from 'use-query-params';

import { SessionSearchOrder } from '@/globalTypes';
import {
  BrandParam,
  InitiatorTypeParam,
  SessionSearchOrderParam,
  SessionStatusParam,
  TerminationCauseParam,
} from '@/utils/query/params';

export const queryParams = {
  sessionId: StringParam,
  countryCode: StringParam,
  playerId: StringParam,
  initiatorId: StringParam,
  initiatorType: InitiatorTypeParam,
  brand: BrandParam,

  userAgent: StringParam,
  status: SessionStatusParam,
  cause: TerminationCauseParam,

  from: DateTimeParam,
  to: DateTimeParam,
  desc: withDefault(BooleanParam, true),
  orderBy: withDefault(SessionSearchOrderParam, SessionSearchOrder.createdAt),
  before: StringParam,
  after: StringParam,
};
