import { useParams } from '@reach/router';
import { graphql } from 'gatsby';
import React, { FC } from 'react';

import {
  Card,
  CardBody,
  CardOptions,
  CardOptionsButton,
  Country,
} from '@/components';
import { useDataGrid } from '@/components/DataGrid';
import { NextIcon, PreviousIcon, RefreshIcon } from '@/components/icons';
import { useTranslate } from '@/contexts';
import { SessionSearchOrder } from '@/globalTypes';
import { assert } from '@/utils/error';
import { SanityPlayerSessionsBlockFragment } from './__generated__/SanityPlayerSessionsBlockFragment';
import usePlayerSessions from './usePlayerSessions';

export const Fragment = graphql`
  fragment SanityPlayerSessionsBlockFragment on SanityPlayerSessionsBlock {
    title {
      ...LocaleString
    }
  }
`;

const PlayerSessionsBlock: FC<{
  block: SanityPlayerSessionsBlockFragment;
}> = ({ block }) => {
  const params = useParams();

  assert(params.playerId, 'missing playerId');

  const {
    sessions,
    fetching,
    nextPage,
    previousPage,
    refresh,
    orderBy,
    desc,
    setOrderBy,
    defaultFilter,
    updateFilter,
  } = usePlayerSessions(params.playerId);

  const { DataGrid, GlobalCardOptions } = useDataGrid({
    name: 'playerSessions',
    data: sessions,
    fetching,
    orderBy,
    setOrderBy,
    defaultFilter,
    updateFilter,
    desc,
    schema: (s) => [
      s.stringValue({
        field: 'uuid',
        title: 'ID',
      }),
      s.dateTimeValue({
        field: 'createdAt',
        title: 'Login At',
        fromFilterField: 'from',
        toFilterField: 'to',
        orderBy: SessionSearchOrder.createdAt,
      }),
      s.dateTimeValue({
        field: 'processedAt',
        title: 'Ended at',
        // Once a session is inactive, processedAt is end of the session
        getValue: ({ row }) => row.status === 'Inactive' && row.processedAt,
      }),
      s.stringValue({
        field: 'ip',
        title: 'IP',
      }),
      s.stringValue({
        field: 'status',
        title: 'Status',
      }),
      s.stringValue({
        field: 'countryCode',
        title: 'Country',
        filterField: 'countryCode',
        wrap: (a) => <Country countryCode={a.row.countryCode} />,
      }),
      s.stringValue({
        field: 'cause',
        title: 'Cause',
      }),
      s.initiatorValue({
        field: 'initiator',
        title: 'Initiator',
      }),
      s.stringValue({
        field: 'userAgent',
        title: 'User Agent',
      }),
    ],
  });
  const { t } = useTranslate();

  return (
    <Card
      size="lg"
      title={t(block.title)}
      showOptionsAtBottom
      options={
        <CardOptions>
          <CardOptionsButton
            disabled={!previousPage}
            onClick={() => previousPage && previousPage()}
          >
            <PreviousIcon />
          </CardOptionsButton>
          <CardOptionsButton
            disabled={!nextPage}
            onClick={() => nextPage && nextPage()}
          >
            <NextIcon />
          </CardOptionsButton>
          <GlobalCardOptions />
          <CardOptionsButton
            onClick={() => refresh({ requestPolicy: 'network-only' })}
          >
            <RefreshIcon />
          </CardOptionsButton>
        </CardOptions>
      }
    >
      <CardBody>
        <DataGrid />
      </CardBody>
    </Card>
  );
};

export default PlayerSessionsBlock;
